import {DatePickerOptions} from 'element-ui/types/date-picker'
import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export default class DatePickerShortcutMixin extends Vue {
    datePickerShortcuts = [{
        text: 'This Week',
        onClick(picker) {
            const start = new Date();
            start.setDate(start.getDate() + 1 - (start.getDay() || 7));
            start.setHours(0, 0, 0, 0)
            const end = new Date(start.getTime())
            end.setDate(end.getDate() + 6);
            picker.$emit('pick', [start.getTime(), end.getTime()])
        }
    }, {
        text: 'This Month',
        onClick(picker) {
            const date = new Date();
            const start = new Date(date.getFullYear(), date.getMonth(), 1);
            const end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            picker.$emit('pick', [start.getTime(), end.getTime()])
        }
    }, {
        text: 'This Year',
        onClick(picker) {
            const date = new Date();
            const start = new Date(date.getFullYear(), 0, 1);
            const end = new Date(date.getFullYear() + 1, 0, 0);
            picker.$emit('pick', [start.getTime(), end.getTime()])
        }
    }, {
        text: 'Last Week',
        onClick(picker) {
            const start = new Date();
            start.setDate(start.getDate() + 1 - (start.getDay() || 7) - 7);
            start.setHours(0, 0, 0, 0)
            const end = new Date(start.getTime())
            end.setDate(end.getDate() + 6);
            end.setHours(0, 0, 0, 0)
            picker.$emit('pick', [start.getTime(), end.getTime()])
        }
    }, {
        text: 'Last Month',
        onClick(picker) {
            const date = new Date();
            const start = new Date(date.getFullYear(), date.getMonth() - 1, 1);
            const end = new Date(date.getFullYear(), date.getMonth(), 0);
            picker.$emit('pick', [start.getTime(), end.getTime()])
        }
    }, {
        text: 'Last 3 Month',
        onClick(picker) {
            const date = new Date();
            const start = new Date(date.getFullYear(), date.getMonth() - 3, 1);
            const end = new Date(date.getFullYear(), date.getMonth(), 0);
            picker.$emit('pick', [start.getTime(), end.getTime()])
        }
    }, {
        text: 'Last Year',
        onClick(picker) {
            const date = new Date();
            const start = new Date(date.getFullYear() - 1, 0, 1);
            const end = new Date(date.getFullYear(), 0, 0);
            picker.$emit('pick', [start.getTime(), end.getTime()])
        }
    }]

    get datePickShortcutSendMessage() {
        const pickerOptions: DatePickerOptions = {
            shortcuts: [{
                text: 'Today',
                onClick(picker) {
                    const date: Date = picker.value as Date || new Date()
                    const now = new Date()
                    date.setDate(now.getDate())
                    date.setMonth(now.getMonth())
                    date.setFullYear(now.getFullYear())
                    picker.$emit('pick', undefined, true)
                        .$nextTick(() => {
                            picker.$emit('pick', date, true)
                        })
                }
            }, {
                text: 'Tomorrow',
                onClick(picker) {
                    const date: Date = picker.value as Date || new Date()
                    const now = new Date()
                    date.setMonth(now.getMonth())
                    date.setFullYear(now.getFullYear())
                    date.setDate(now.getDate() + 1)
                    picker.$emit('pick', undefined, true)
                        .$nextTick(() => {
                            picker.$emit('pick', date, true)
                        })
                }
            }]
        }

        for (let i = 8; i <= 11; i++) {
            pickerOptions.shortcuts!!.push({
                text: `${i}pm`,
                onClick(picker) {
                    const date: Date = picker.value as Date || new Date()
                    date.setHours(i + 12)
                    date.setMinutes(0)
                    date.setSeconds(0)
                    date.setMilliseconds(0)
                    picker.$emit('pick', undefined, true)
                        .$nextTick(() => {
                            picker.$emit('pick', date, true)
                        })
                }
            })
        }

        return pickerOptions
    }
}
